$primary-color: #0c00aa;
$link-color: #000000;
$background-color: #f5f5f5;
$menu-color: #ffffff;
$card-color: #ffffff;
$border-color: #80808057;

:export {
  primaryColor: $primary-color;
  linkColor: $link-color;
  backgroundColor: $background-color;
  cardColor: $card-color;
  borderColor: $border-color;
}
