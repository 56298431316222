.shimmer {
  background: linear-gradient(to right, #f5f5f5 0%, #efefef 15%, #f5f5f5 30%);
  animation: shimmer 3s linear infinite;
  background-size: 1200px 100%;
}
.shimmer.dark {
  background-image: linear-gradient(
    to right,
    #d0d0d0 0%,
    #c1c0c0 15%,
    #d0d0d0 30%
  );
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0%;
  }
  100% {
    background-position: 1200px 0%;
  }
}
