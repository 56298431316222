// Local style imports.
@import "./reset";
@import "../../node_modules/@equiem/react-admin-ui/styles/global";
@import "./variables.module";
@import "../../node_modules/react-grid-layout/css/styles";
@import "../../node_modules/react-resizable/css/styles";

body {
  background: $background-color;
  cursor: default;
}

a {
  color: $link-color;
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

.react-grid-layout {
  margin-top: -10px;
}

.smooth-fade-transition {
  transition: opacity 300ms;
}

.bc-link:hover {
  color: #000000 !important;
}

.__react_component_tooltip {
  max-width: 165px !important;
  padding: 8px !important;
}

input:placeholder-shown {
  text-overflow: ellipsis;
}

::-moz-selection,
::selection,
input::-moz-selection,
input::selection,
input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
  background: #b4d7fe;
  outline: none;
}
